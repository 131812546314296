import * as React from "react";
import Hero from "../components/homepage/hero";
import Features from "../components/homepage/Features";
import Details from "../components/homepage/Details";
import CTA from "../components/homepage/CTA";
import HeroLp1 from "../components/homepage/heroLp1";

const IndexPage = () => {
  return (
    <>
      <HeroLp1 />
      <Features></Features>
      <Details></Details>
      <CTA></CTA>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
