import React, { useContext } from "react";
import styled from "styled-components";
import Main from "./styles/mainStyle";
import MainWrapper from "./styles/mainWrapperStyle";
import background from "../../images/homepage/bg-lp1.png";
import { Link } from "gatsby";
import Win7 from "../../icons/shared/win7.svg";
import Win10 from "../../icons/shared/win10.svg";
import Win11 from "../../icons/shared/win11.svg";
import Win from "../../icons/shared/win-white.svg";
import { GlobalContext } from "../../Contexts/GlobalContext";

import Checkmark from "../../icons/shared/checkmark.svg";

const HeroWrapper = styled(MainWrapper)`
  @media (max-width: 675px) {
    flex-wrap: wrap;
  }
  max-width: 1100px;
  min-height: 500px;
  padding: 32px 0;

  margin-top: 0%;
  margin-bottom: 0%;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    /* min-width: 700px; */
    overflow: visible;
    width: 55%;

    img {
      width: 200%;
      max-width: 700px;
      height: auto;
      overflow: visible !important;
      margin: auto;
    }

    @media (max-width: 980px) {
      width: 50%;
    }

    @media (max-width: 675px) {
      margin-top: 32px;
      width: 100%;
      img {
        width: 100%;
        max-width: 700px;
        height: auto;
        overflow: visible !important;
        margin: auto;
      }
    }
  }
`;

const MainStyle = styled(Main)`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
`;

const About = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 900px) {
    min-width: 390px;
  }

  @media (max-width: 600px) {
    width: 90%;
    min-width: 200px;
  }
`;

const Title = styled.h1`
  font-family: "BSC Bold";
  /* font-weight: 800; */
  font-style: bold;
  font-weight: bold;
  font-size: 65px;
  line-height: 78px;
  span {
    color: red;
    font-family: "BSC Bold";
  }
  color: #000000;

  @media (max-width: 900px) {
    font-size: 55px;
    line-height: 68px;
  }

  @media (max-width: 600px) {
    font-size: 45px;
    line-height: 58px;
  }
`;

const SubTitle = styled.h2`
  font-family: "BSC Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  margin: 0;
  span {
    color: red;
    font-family: "BSC Medium";
  }
  color: #000000;

  @media (max-width: 1100px) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: 590px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Slogan = styled.div`
  font-family: "BSC Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20.4px;
  max-width: 400px;
  margin-top: 8px;
  color: #000000;
`;
const ButtonBackground = styled.div`
  background: #e10a0a;
  width: 100%;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
`;

const BuyButton = styled.button`
  font-family: "BSC Medium";
  width: 100%;
  height: 70px;
  background: #fd0e0e;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-top: 20px;
  letter-spacing: 0.96px;
  cursor: pointer;
  :hover {
    background: rgb(219, 70, 42);
    color: white;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5%;
    max-width: 29px;
    max-height: 29px;
  }
`;
const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  a {
    text-decoration: none;
    width: 100%;
  }
  @media (max-width: 675px) {
    align-items: center;
  }
`;
const ButtonUnderText = styled.div`
  font-family: "BSC Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  margin-top: 5%;
  text-align: left;
  @media (max-width: 675px) {
    text-align: center;
  }
`;

const Compatible = styled.div`
  font-family: "BSC Regular";
  div {
    font-size: 16px;
    line-height: 19px;
    font-family: "BSC Regular";
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .win {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 28px;
    svg {
      max-width: 30px;
      max-height: 30px;
    }
    div {
      font-size: 13px;
      line-height: 16px;
      margin-top: 8px;
      text-align: center;
    }
  }
`;

const KeyFeatures = styled.div`
  margin-top: 26px;
  width: 100%;

  div {
    font-family: "BSC Regular";
  }
  .title {
    font-size: 20px;
    line-height: 24px;
    font-family: "BSC Medium";
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    width: 100%;
    justify-content: space-around;
    @media (max-width: 600px) {
      flex-wrap: wrap;
      flex-direction: column;

      .feature {
        margin: 16px;
      }
    }
  }

  .feature {
    display: flex;
    flex-direction: row;
    .checkmark {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 8px;

      svg {
        min-width: 22px;
        min-height: 22px;
      }

      .check {
        fill: red;
        color: red;
      }
    }
    .desc {
      font-size: 17px;
      line-height: 22px;
    }
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin: 24px 0;
`;

const Description = styled.div`
  .title {
    font-family: "BSC Medium";
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-family: "BSC Regular";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media (max-width: 900px) {
    width: 55%;
    min-width: 390px;
  }
  @media (max-width: 600px) {
    width: 90%;
    min-width: 200px;
  }
`;

const Specs = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 32px;
  margin-top: 32px;

  .title {
    font-family: "BSC Medium";
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  .spec {
    font-family: "BSC Regular";
    font-size: 15px;
    line-height: 30px;
  }
`;
function HeroLp1() {
  const { urlParameter, isMac, setMacOpen } = useContext(GlobalContext);
  const queryString =
    typeof window !== "undefined" ? window.location.search : "";
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  function addParametersToUrl() {
    if (urlParameter !== "" && queryString.length === 0) {
      window.history.pushState(
        { path: url + "?" + urlParameter },
        "",
        url + "?" + urlParameter
      );
    }
  }

  return (
    <MainStyle style={{ backgroundImage: `url(${background})` }}>
      <HeroWrapper>
        <About>
          <Title style={{ marginBottom: `0` }}>
            <span>PDF</span> READER 10
            <Compatible>
              <div>Compatible With</div>
              <div className="row">
                <div className="win">
                  <Win11 />
                  <div>Windows 11</div>
                </div>
                <div className="win">
                  <Win10 />
                  <div>Windows 10</div>
                </div>
                <div className="win">
                  <Win7 />
                  <div>Windows 7</div>
                </div>
              </div>
            </Compatible>
          </Title>
          <KeyFeatures>
            <div className="title">Key Features</div>
            <div className="row">
              <div className="feature">
                <div className="checkmark">
                  <Checkmark />
                </div>
                <div className="desc">
                  Create PDF from <br />
                  any application
                </div>
              </div>
              <div className="feature">
                <div className="checkmark">
                  <Checkmark />
                </div>
                <div className="desc">
                  Fill and sign <br />
                  forms
                </div>
              </div>
              <div className="feature">
                <div className="checkmark">
                  <Checkmark />
                </div>
                <div className="desc">
                  Open and read <br />
                  PDF files
                </div>
              </div>
            </div>
          </KeyFeatures>
          <Line />
          <Description>
            <div className="title">A fast and powerful PDF Reader</div>
            <p>
              This latest version of PDF Reader allows you to read, create, edit
              and convert PDF files easily. With features that let you fill and
              sign forms, you will be able to speed up your workflows and reduce
              the need to print and scan documents.
            </p>
            <p>
              The most complete PDF reader on the market, PDF reader gives you
              everything you need to work efficiently with documents so you can
              spend more time on the tasks that matter most.
            </p>
          </Description>
        </About>
        <CTA>
          <ButtonHolder>
            {!isMac ? (
              <Link
                className="tracker"
                to="https://track.pdfpro10.com/product/normal/pdfreader/trial"
              >
                <ButtonBackground>
                  <BuyButton>
                    DOWNLOAD NOW <Win />
                  </BuyButton>
                </ButtonBackground>
              </Link>
            ) : (
              <Link>
                <ButtonBackground
                  onClick={() => {
                    addParametersToUrl();
                    setMacOpen(true);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "DownloadClick",
                      category: "download",
                      action: "click",
                      label: "download_trial",
                    });
                  }}
                >
                  <BuyButton>
                    DOWNLOAD NOW <Win />
                  </BuyButton>
                </ButtonBackground>
              </Link>
            )}
          </ButtonHolder>
          <Specs>
            <div className="title">Application Specs</div>
            <div className="spec">Title: PDF Reader 10</div>
            <div className="spec">Requirements: Window 10, 8, 7</div>
            <div className="spec">Language: English</div>
            <div className="spec">Available languages: EN, FR, DE, ES</div>
            <div className="spec">Version: 10.04.05</div>
            <div className="spec">Latest Update: Nov. 14, 2021</div>
            <div className="spec">File size: 29.17MB</div>
            <div className="spec">Downloads: 1+ million</div>
          </Specs>
        </CTA>
      </HeroWrapper>
    </MainStyle>
  );
}

export default HeroLp1;
